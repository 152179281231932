/* eslint-disable max-len */
// TODO (ClickFunnels2 - Editor Team): Use countdown dependency to all countdown elements https://cdn.jsdelivr.net/gh/kbwood/countdown
// TODO (ClickFunnels2 - Editor Team): Use moment dependency to all countdown elements. https://cdnjs.cloudflare.com/ajax/libs/moment.js
// TODO (ClickFunnels2 - Editor Team): Use moment-timezone dependency to all countdown elements. https://cdnjs.cloudflare.com/ajax/libs/moment-timezone
// TODO (ClickFunnels2 - Editor Team): Use addevent dependency to autowebinar_ty_addevent element. https://addevent.com/libs/atc/1.6.1
// TODO (ClickFunnels2 - Editor Team): Use moment dependency to elVideoUnlockerElement element. https://cdnjs.cloudflare.com/ajax/libs/moment.js/
// TODO (ClickFunnels2 - Editor Team): Use moment-timezone dependency to elVideoUnlockerElement element. https://cdnjs.cloudflare.com/ajax/libs/moment-timezone
// TODO (ClickFunnels2 - Editor Team): Use social-likes dependency to _multichoice.html.erb element. <script src="https://cdnjs.cloudflare.com/ajax/libs/social-likes
// https://3.basecamp.com/4903236/buckets/19702927/todos/3503657437

require('./lander/utils/replace_tag')
require('./lander/utils/fetcher')
require('Shared/javascript/parseurl')

require('./lander/audio_player')
require('./lander/vendor/garlic.cf.js')

require('./lander/populate_select')
require('./lander/runtime_events')
require('./lander/fhl_handle_select_transformation')
require('./lander/track_events')
require('./lander/workspace_sharing')
require('./lander/rebilly_element')
require('./lander/upsell_element')
require('./lander/order/main')

/** New runtime modules in typescript **/

require('./lander/runtime')
require('./lander/action_link')
require('./lander/animation')
require('./lander/linkable')
require('./lander/submit')
require('./lander/sticky')

window.nanostores = require('nanostores')

const originalFetch = window.fetch
window.inflightRequests = 0
window.fetch = async (request, init) => {
  window.inflightRequests++
  try {
    const rendered_page_url = document.querySelector('meta[name="rendered-page-url"]')?.getAttribute('content')
    if (rendered_page_url) {
      const url = new URL(new Request(request).url)
      if (url.hostname == window.location.hostname) {
        init = init || {}
        if (!init.headers) init.headers = {}
        init.headers['X-Rendered-Page-Url'] = rendered_page_url
      }
    }
  } catch (e) {
    console.error('Failed to set X-Rendered-Page-Url header', e)
  }
  return originalFetch(request, init).finally(() => {
    window.inflightRequests--
  })
}

// NOTE: This is custom code app. Mainly applying garlic to all form fields labelled as
// "elFormItem" and without the data-prevent-garlic attribute.
$(window).on('DOMContentLoaded', function () {
  $('.elFormItem:not([data-prevent-garlic="true"])').each(function () {
    let onPersist = () => {}
    if (this.getAttribute('name') == 'name') {
      // NOTE: when changing full_name field, we need to update first_name and last_name fields
      // so that they are in sync with the full_name field when the page is reloaded.
      onPersist = () => {
        const value = this.value.trim()
        const firstName = value.split(' ')[0]
        const lastName = value.split(' ').slice(1).join(' ')
        window.cfGarlicUtils.store('first_name', firstName)
        window.cfGarlicUtils.store('last_name', lastName)
      }
    } else if (this.getAttribute('name') == 'phone_number') {
      onPersist = () => {
        if (!this.iti?.isValidNumber()) return
        const value = this.iti?.getNumber() ?? this.value.trim()
        window.cfGarlicUtils.store('phone_number', value)
      }
    }
    $(this).garlic({
      onPersist,
      onRetrieve: function ($el, retrievedValue) {
        const elemName = $el.attr('name')
        globalThis.CFGarlicValues[elemName] = retrievedValue

        $el.each((_, element) => {
          const closestBlueprintInstance = element.closest('[data-page-element]')?.cf2_instance
          if (closestBlueprintInstance && closestBlueprintInstance.update) {
            closestBlueprintInstance.update(retrievedValue)
          }
        })
      },
    })
  })
})
